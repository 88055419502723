<template>
	<div class="role">
		<div class="right-wrap" v-if="!isApiDetail">
			<div class="query-wrap">
				<div class="qjc-flex">
					<div class="query-btn-list">
						<el-button :class="queryBtnVal == 'pay' ? 'query-btn-item active' : 'query-btn-item'" @click="handleUsed('pay')">prepay</el-button>
						<el-button :class="queryBtnVal == 'free' ? 'query-btn-item active' : 'query-btn-item' " @click="handleUsed('free')">free</el-button>
					</div>
					<!-- <div class="query-date">
						<div class="query-date-label">调用时间</div>
						<el-date-picker
						  v-model="searchDate"
						  type="daterange"
						  value-format="timestamp"
						  range-separator="~"
						  start-placeholder="开始日期"
						  end-placeholder="结束日期"
						  @change="searchDateChange">
						</el-date-picker>
					</div> -->
				</div>
				<!-- <el-button class="query-btn" @click="handleQuery">筛选</el-button> -->
			</div>
			<el-table
				:data="tableData"
				style="width: 100%"
				border
				row-key="id"
				class="list" >
				<template slot="empty">
					<div class="empty-wrap">
						<el-image class="empty-img" :src="require('@/assets/images/empty-download.png')"></el-image>
						<div class="empty-text">No data available</div>
					</div>
				</template>
				<el-table-column
					prop="created_at"
					label="statistics"
					align="center"
					class-name="created_at"
				>	
					<template slot="header" slot-scope="{ column,$index }">
						<div class="time-header">
							commercial user identification
						</div>
					</template>
					<template slot-scope="scope">
						<div class="">{{scope.row.name}}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="pay_mode"
					label="payment method"
					align="center"
					class-name="pay_mode"
				>
					<template slot-scope="scope">
						<div class="">{{queryBtnVal == 'free' ? 'free' : scope.row.pay_mode == '1' ? 'prepay' : scope.row.pay_mode == '2' ? 'postpaid' : '--'}}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="api"
					label="Quantity of prepayed reports used"
					align="center"
					class-name="created_at"
				>
				<template slot-scope="scope">
					<div class="" v-if="scope.row.api != null">
						{{( scope.row.api != null && scope.row.api.hasOwnProperty('used') && queryBtnVal == 'pay') ? scope.row.api.used : ( scope.row.api != null && scope.row.api.hasOwnProperty('freeused') && queryBtnVal == 'free') ? scope.row.api.freeused : '0' }}
					</div>
					<div class="" v-else>--</div>
				</template>
				</el-table-column>
				<el-table-column
					prop="api"
					label="Quantity of prepayed reports remaining"
					align="center"
					class-name="mobile"
				>
					<template slot-scope="scope">
						<div class="" v-if="scope.row.pay_mode == '1'">
							{{ (scope.row.api != null && scope.row.api.hasOwnProperty('times') && queryBtnVal == 'pay') ? scope.row.api.times :  (scope.row.api != null && scope.row.api.hasOwnProperty('freetimes') && queryBtnVal == 'free') ? scope.row.api.freetimes : '--' }}
						</div>
						<div class="" v-if="scope.row.pay_mode == '2'">--</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="action"
					label="operation"
					align="center"
					class-name="mobile"
				>
					<template slot-scope="scope">
						<div class="action-detail" @click="handleDetail(scope.row.id)">View Details</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="right-wrap" v-else>
			<el-table
				:data="historyList"
				style="width: 100%"
				row-key="id"
				class="list" >
				<template slot="empty">
					<div class="empty-wrap">
						<el-image class="empty-img" :src="require('@/assets/images/empty-download.png')"></el-image>
						<div class="empty-text">No data available</div>
					</div>
				</template>
				<!-- :height="mainHeight - 160 + 'px'" -->
				<el-table-column
					prop="id"
					label="Serial number"
					align="center"
					width="100"
					class-name="pay_mode"
				>
					<template slot-scope="scope">
						<div class="">{{scope.row.id ? scope.row.id: '--'}}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					label="name"
					align="center"
					class-name="pay_mode"
				>
					<template slot-scope="scope">
						<div class="">{{scope.row.name}}<span :class="scope.row.categ == 'HEA3' ? 'danger-color' : ''">{{scope.row.categ == 'HEA1' ? '(healthy)' : scope.row.categ == 'HEA2' ? '(subhealthy)' : scope.row.categ == 'HEA3' ? '(medical care needed)' : '' }}</span></div>
					
					</template>
				</el-table-column>
				<el-table-column
					prop="id_card"
					label="I.D. number"
					align="center"
					class-name="pay_mode"
				>
					<template slot-scope="scope">
						<div class="">{{scope.row.id_card ? scope.row.id_card: "--"}}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="created_at"
					label="Product & AI module name"
					align="center"
					class-name="created_at"
				>	
					<template slot-scope="scope">
						<div class="">{{scope.row.package ? scope.row.package : '--'}}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="settle"
					label="payment method"
					align="center"
					class-name="pay_mode"
				>
					<template slot-scope="scope">
						<div class="">{{scope.row.settle == '0' ? 'free' : scope.row.settle == '1' ? 'prepay' : '--'}}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="created_at"
					label="dates"
					align="center"
					class-name="mobile"
				>
					<template slot-scope="scope">
						<div>{{scope.row.created_at}}</div>
					</template>
				</el-table-column>
			</el-table>
			<!-- <div class="down-cell">
				<el-button class="down-btn" @click="handleDown">下载</el-button>
			</div> -->
			<div class="foot-pagination">
				<el-pagination
					class="pagination qjc-texta-c"
					layout="prev, pager, next"
					hide-on-single-page
					:current-page.sync="pager.page"
					:page-count="pager.pagecount"
					:page-size="pager.pagesize"
					:total="pager.count"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getStatistics, getStatisticsApi, enterpriseList, mainDataQuery } from "@/utils/axios.js"
	
	export default{
		name: 'mainStatistics',
		data () {
			return {
				dateValue: '',
				tableData: [],
				pageCount: 0,
				pager: {
					count: 0,
					page: 1,// 默认第一页
					pagecount: 0,
					pagesize: 15//默认一页15条数据
				},
				isApiDetail: false,
				checked: true,
				adminId: '',
				id: '',
				enpId: '',
				companyList: [],
				historyList: [],
				searchDate: '',//检索日期
				queryBtnVal: 'pay',
				mainHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60,
				list:{
					package: [
						{
							name: '健康100',
							package: '1',
							used: '',
							times: '',
						},
						{
							name: '自由定制',
							package: '2',
							used: '',
							times: '',
							custom: {
								base: {
									name: 'M20基础套餐',
									used: '',
									times: '',
								},
								packageA: {
									name: 'A1同族群PLM对比',
									used: '',
									times: '',
								},
								packageB: {
									name: 'B1共病风险',
									used: '',
									times: '',
								},
								packageC: {
									name: 'C1疾病风险远期预测',
									used: '',
									times: '',
								},
								packageD: {
									name: 'D1健康促进方案',
									used: '',
									times: '',
								},
								packageFB: {
									name: 'QBTB骨折预测',
									used: '',
									times: '',
								}
							}
						},
						{
							name: 'GBP馨享母婴',
							package: '3',
							used: '',
							times: '',
						}
					]
				}
			}
		},
		mounted() {
			let adminId = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).issupper : '0'
			this.adminId = adminId
			
			this.handleLoad()
		},
		methods:{
			handleLoad() {
				getStatistics({act:"api"}).then((res) => {
					// console.log(res)
					if(res.code == 200) {
						this.tableData = res.data
						this.id = res.data.id
					}
				})
			},
			handleDetail(id) {
				this.id = id
				this.isApiDetail = true
				this.handleHistoryList(id)
			},
			handleHistoryList(id) {
				getStatisticsApi({
					page: this.pager.page,
					pagesize: this.pager.pagesize,
					corp_id: id 
				}).then((res) => {
					if(res.code == 200){
						this.historyList = res.data.data
						var pager = res.data.pager;
						for(var key in pager){
							pager[key] = parseInt(pager[key]);
						}
						this.pager = pager;
					}
				})
			},
			searchDateChange(daterange) {
				// this.getHistoryList();
				// console.log(daterange)
			},
			getReport() {
				
			},
			handleCurrentChange() {
				
			},
			pageChange(page) {
				this.pager.page = page;
				this.handleHistoryList(this.id)
			},
			handleUsed(val) {
				this.queryBtnVal = val
			},
			handleDown() {
				
			},
			
		}
	}
</script>

<style scoped>
	.right-wrap >>> .el-table th {
		background-color: #F2F4FA;
	}
	
	.right-wrap >>> .el-date-editor.el-input, .el-date-editor.el-input__inner {
		width: 380px;
	}
	
	.right-wrap >>> .el-date-editor .el-range-separator {
		color: #DBDDE6;
	}
	.right-wrap >>> .el-table th>.cell {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: bold;
		color: #293033;
	}
	.right-wrap >>> .el-table td, .right-wrap >>> .el-table th.is-leaf {
		border-color: #CED4F0;
	}
	.right-wrap >>> .el-table--border th, .right-wrap >>> .el-table__fixed-right-patch {
		border-color: #CED4F0;
	}
	.right-wrap >>> .el-table--border td, .right-wrap >>> .el-table--border th, .right-wrap >>> .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-color: #CED4F0;
	}
</style>
<style lang="scss" scoped>
	.role{
		display: flex;
		// padding: 24px;
		width: 100%;
		height: 100%;
		// border: 12px solid #F2F4FA;
		
		.time-header {
			position: relative;
			.times-used {
				position: absolute;
				top: 0;
				left: 0;
			}
			.tiems-line {
				position: absolute;
				top: 26px;
				left: 36px;
				width: 36px;
				height: 1px;
				transform: rotate(-45deg);
				background-color: #CED4F0;
			}
			.times-surplus {
				position: absolute;
				top: 30px;
				right: 0;
			}
		}
		.left-menus {
			min-width: 170px;
			width: 170px;
			padding: 0 24px 30px;
			border-right: 1px solid #E5E8F5;
			background-color: #ffffff;
			overflow-y: auto;
			
			.add-action {
				display: flex;
				align-items: center;
				cursor: pointer;
				
				.add-action-img {
					display: flex;
					align-items: center;
					align-items: center;
					width: 24px;
					height: 24px;
					background-color: #6883FB;
					border-radius: 50%;
					
					&::after {
						content: "+";
						width: 100%;
						font-size: 20px;
						color: #fff;
						text-align: center;
						margin-bottom: 4px;
					}
				}
				.add-action-text {
					font-size: 14px;
					color: #6883FB;
					margin-left: 8px;
				}
			}
			.left-list {
				padding-top: 20px;
			}
			
			.left-enp-item {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 12px 0;
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #525866;
				cursor: pointer;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
				&:hover {
					color: #7A92FB;
				}
			}
			.left-enp-item-active {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 12px 0;
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 500;
				color: #6883FB;
				cursor: pointer;
			}
			
			.enp-item-status {
				width: 20px;
				height: 20px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FF5219;
				background-color: #FDE8E2;
				margin-right: 4px;
				padding-top: 1px;
				border-radius: 50%;
				text-align: center;
			}
			
			.enp-item-name {
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			.enp-item-status-active {
				width: 20px;
				height: 20px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #6883FB;
				background-color: #DCE3FF;
				margin-right: 4px;
				padding-top: 1px;
				border-radius: 50%;
				text-align: center;
			}
		}
		
		.right-wrap {
			flex: 1;
			min-width: 1048px;
			padding: 28px 24px;
			.query-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;
				
				.query-package {
					width: 226px;
				}
			}
			.qjc-flex {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			
			.c-danger{
				color: #FF4C52;
			}
			
			.query-date {
				display: flex;
				align-items: center;
				// margin-right: 150px;
				
				.query-date-label {
					margin-right: 10px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #525866;
				}
			}
			.query-btn-list {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.query-btn-list .query-btn-item {
				width: 86px;
				height: 34px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #525866;
				border-radius: 4px;
				background-color: #fff;
				border: 1px solid #DBDDE6;
			}
			.query-btn-list .active{
				color: #FFFFFF;
				border: none;
				background-color: #6883FB;
			}
			.query-btn {
				width: 96px;
				height: 34px;
				background: #6883FB;
				border-radius: 17px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				border: none;
			}
			
		}
		
		.pagination{
			text-align: center;
		}
		
		.foot-pagination {
			// position: fixed;
			// bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 1160px;
			padding: 18px 0;
			// height: 80px;
			// background-color: #fff;
			// z-index: 9;
		}
		
		.nextBtn {
			position: fixed;
			left: 280px;
			bottom: 24px;
			display: block;
			cursor: pointer;
			width: 96px;
			height: 34px;
			height: 34px;
			background: #6883FB;
			border-radius: 17px;
			border: none;
			color: white;
			outline: none;
			font-size: 14px;
		}
		
	}
	.contrast {
		position: relative;
		width: 96px;
		height: 34px;
		text-align: center;
		line-height: 34px;
		border-radius: 17px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		background: #6883FB;
		z-index: 99;
		margin-left: 40px;
		cursor: pointer;
	}
	.action-detail {
		cursor: pointer;
	}
	.down-btn {
		position: relative;
		z-index: 99;
		width: 86px;
		height: 34px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #fff;
		border-radius: 4px;
		background-color: #6883FB;
		border: none;
		margin-top: 0.2rem;
	}
	
	.danger-color {
		color: #FF3131;
	}
	
	.empty-wrap {
		padding: 100px 0;
	}
	
	.empty-img {
		width: 222px;
		height: 206px;
	}
</style>